var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "container x-start",
    },
    [
      _c(
        "div",
        { staticClass: "formBox", staticStyle: { width: "100%" } },
        [
          _c("topOperatingButton", {
            ref: "topOperatingButton",
            attrs: {
              isShowImg: true,
              isShowUnit: Boolean(
                !["dianCan", "cake"].includes(_vm.$route.query.type)
              ),
              isExamineBtn: false,
              showAudit: false,
              isSubmitAddBtn: false,
              isAuditBillBtn: false,
              isStopBtn: false,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submit(false)
              },
              getQuit: _vm.getQuit,
              synchronousImg: _vm.synchronousImg,
              synchronousUnit: _vm.synchronousUnit,
            },
          }),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c("cardTitleCom", {
                attrs: { cardTitle: "基本信息" },
                scopedSlots: _vm._u([
                  {
                    key: "cardContent",
                    fn: function () {
                      return [
                        _c("div", [
                          _c("div", { staticClass: "padd15 x-bc" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "overflow-x": "hidden",
                                  width: "100%",
                                  "box-sizing": "border-box",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "商品图片",
                                      prop: "goodsUrl",
                                    },
                                  },
                                  [
                                    _c("AccessoryUpload", {
                                      attrs: {
                                        title: "",
                                        limit: Number(6),
                                        listType: "picture-card",
                                        fileSize: 1,
                                        fileList: _vm.fileList,
                                        noneBtnImg:
                                          _vm.form.goodsImages.length >= 6,
                                      },
                                      on: {
                                        getFileItems: _vm.getGoodsUrls,
                                        delFileItems: _vm.delGoodsUrl,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                !["dianCan", "cake"].includes(
                                  _vm.$route.query.type
                                )
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "上传视频",
                                          prop: "goodsUrl",
                                        },
                                      },
                                      [
                                        _c("OssUpload", {
                                          attrs: {
                                            option: {
                                              listType: "video",
                                              type: "mp4",
                                              showList: false,
                                              clear: true,
                                              size: "20mb",
                                            },
                                          },
                                          on: { change: _vm.changeOss },
                                          model: {
                                            value: _vm.form.video.accessUrl,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.video,
                                                "accessUrl",
                                                $$v
                                              )
                                            },
                                            expression: "form.video.accessUrl",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品编码",
                                      prop: "goodsNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "", placeholder: "" },
                                      model: {
                                        value: _vm.form.goodsNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "goodsNo", $$v)
                                        },
                                        expression: "form.goodsNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品名称",
                                      prop: "goodsName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "", placeholder: "" },
                                      model: {
                                        value: _vm.form.goodsName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "goodsName", $$v)
                                        },
                                        expression: "form.goodsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品分类",
                                      prop: "categoryId",
                                    },
                                  },
                                  [
                                    _c("Treeselect", {
                                      staticStyle: { "z-index": "666" },
                                      attrs: {
                                        options: _vm.categoryTreeOptions,
                                        "show-count": true,
                                        placeholder: "",
                                        normalizer: _vm.normalizer1,
                                        open: _vm.getTreeselect,
                                      },
                                      on: { select: _vm.select },
                                      model: {
                                        value: _vm.form.categoryId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "categoryId", $$v)
                                        },
                                        expression: "form.categoryId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.$route.query.type == "cake" ||
                                _vm.$route.query.type == "dianCan"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "w33",
                                        attrs: {
                                          label: "商品品牌",
                                          prop: "brandId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.brandName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "brandName",
                                                $$v
                                              )
                                            },
                                            expression: "form.brandName",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-form-item",
                                      {
                                        staticClass: "w33",
                                        attrs: {
                                          label: "商品品牌",
                                          prop: "brandId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              disabled: "",
                                              clearable: "",
                                              placeholder: "",
                                              "element-loading-text":
                                                "数据加载中",
                                            },
                                            model: {
                                              value: _vm.form.brandId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "brandId",
                                                  $$v
                                                )
                                              },
                                              expression: "form.brandId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.brandData,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.brandId,
                                                attrs: {
                                                  label: item.brandName,
                                                  value: item.brandId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                _vm.$route.query.type != "diancan"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "w33",
                                        attrs: {
                                          label: "商品排序",
                                          prop: "sortNo",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "输入商品排序",
                                            maxlength: "5",
                                          },
                                          model: {
                                            value: _vm.form.sortNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "sortNo",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "form.sortNo",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.$route.query.type != "dianCan"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "w33",
                                        attrs: {
                                          label: "商品卖点",
                                          prop: "goodsSpec",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "60",
                                            placeholder: "输入商品卖点",
                                          },
                                          model: {
                                            value: _vm.form.sellingPoints,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "sellingPoints",
                                                $$v
                                              )
                                            },
                                            expression: "form.sellingPoints",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "specList",
                                    attrs: {
                                      label: "规格列表",
                                      prop: "goodsUnit",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "goodsSpecList",
                                        staticStyle: { overflow: "auto" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "specItem" },
                                          [
                                            _c("goodsSpecItem", {
                                              attrs: {
                                                type: _vm.$route.query.type,
                                                goodsUnit: _vm.form.goodsUnit,
                                                basicGoodsUnit:
                                                  _vm.form.basicGoodsUnit,
                                                machiningData:
                                                  _vm.allMachiningGroupData,
                                                unitList: _vm.unitList,
                                                referUnitData:
                                                  _vm.referUnitData,
                                                updateDisabled:
                                                  _vm.form.isEnableShopPrice,
                                              },
                                              on: {
                                                handleAddMachiningGroup:
                                                  _vm.handleAddMachiningGroup,
                                                handleAddMachining:
                                                  _vm.handleAddMachining,
                                                delGoodsSpec:
                                                  _vm.handleDelGoodsSpec,
                                                setUnit: _vm.setUnit,
                                                refreshReferUnitData:
                                                  _vm.refreshReferUnitData,
                                                openSelectGoods:
                                                  _vm.openSelectGoods,
                                                handleCollocationTableRow:
                                                  _vm.handleCollocationTableRow,
                                                collocationTableKeyup:
                                                  _vm.collocationTableKeyup,
                                                setShopPriceItems:
                                                  _vm.setShopPriceItems,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.$route.query.type != "dianCan"
                            ? _c(
                                "div",
                                { staticClass: "padd15" },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _vm.$route.query.type != "cake"
                                        ? _c(
                                            "el-checkbox",
                                            {
                                              on: {
                                                change: function ($event) {},
                                              },
                                              model: {
                                                value:
                                                  _vm.form.isEnableShopPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "isEnableShopPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.isEnableShopPrice",
                                              },
                                            },
                                            [_vm._v("商城门店价格策略 ")]
                                          )
                                        : _c(
                                            "el-checkbox",
                                            {
                                              on: {
                                                change: function ($event) {},
                                              },
                                              model: {
                                                value:
                                                  _vm.form.isEnableShipPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "isEnableShipPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.isEnableShipPrice",
                                              },
                                            },
                                            [_vm._v("商城门店价格策略 ")]
                                          ),
                                      _vm.form.isEnableShopPrice ||
                                      _vm.form.isEnableShipPrice
                                        ? _c(
                                            "el-table",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                data: _vm.form
                                                  .shopMatchingItems,
                                                border: "",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "barcode",
                                                  align: "center",
                                                  label: "商品条码",
                                                  width: "180",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "goodsSpec",
                                                  align: "center",
                                                  label: "规格名称",
                                                  width: "180",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "unitName",
                                                  align: "center",
                                                  label: "单位",
                                                  width: "180",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "address",
                                                  label: "店铺",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openShopStrategy(
                                                                      scope.$index,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " 设置门店 "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3144246375
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("cardTitleCom", {
                attrs: { cardTitle: "其他信息" },
                scopedSlots: _vm._u([
                  {
                    key: "cardContent",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "padd15",
                            staticStyle: { "padding-bottom": "100px" },
                          },
                          [
                            _vm.$route.query.type != "dianCan"
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "saleType",
                                      label: "售卖方式",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: _vm.form.saleType,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "saleType", $$v)
                                          },
                                          expression: "form.saleType",
                                        },
                                      },
                                      [_vm._v("现货")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "2" },
                                        model: {
                                          value: _vm.form.saleType,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "saleType", $$v)
                                          },
                                          expression: "form.saleType",
                                        },
                                      },
                                      [_vm._v("预售")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.form.saleType === "2" &&
                            _vm.$route.query.type != "dianCan"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "330px" },
                                    attrs: { label: "预售时间" },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticClass: "inputArrears",
                                      attrs: {
                                        format: "yyyy-MM-dd HH:mm:ss",
                                        "value-format": "yyyy-MM-dd HH:mm:ss",
                                        type: "datetimerange",
                                        "default-time": [
                                          "00:00:00",
                                          "23:59:59",
                                        ],
                                        "start-placeholder": "预售开始时间",
                                        "end-placeholder": "预售结束时间",
                                      },
                                      model: {
                                        value: _vm.form.saleTypeTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "saleTypeTime",
                                            $$v
                                          )
                                        },
                                        expression: "form.saleTypeTime",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$route.query.type != "dianCan"
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "fetchTypeItems",
                                      label: "配送方式",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.form.fetchTypeItems,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "fetchTypeItems",
                                              $$v
                                            )
                                          },
                                          expression: "form.fetchTypeItems",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: 1 } },
                                          [_vm._v("门店自提")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: 2 } },
                                          [_vm._v("同城配送")]
                                        ),
                                        _vm.$route.query.type != "cake"
                                          ? _c(
                                              "el-checkbox",
                                              { attrs: { label: 3 } },
                                              [_vm._v("快递配送")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !["dianCan", "cake"].includes(_vm.$route.query.type)
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "370px" },
                                    attrs: { label: "备货时间" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-x" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              width: "50px",
                                            },
                                          },
                                          [_vm._v("下单")]
                                        ),
                                        _c("el-input", {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "number",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInputlength(
                                                _vm.form.stockUpHour,
                                                _vm.form,
                                                "stockUpHour",
                                                true,
                                                1,
                                                false,
                                                24
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.stockUpHour,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "stockUpHour",
                                                $$v
                                              )
                                            },
                                            expression: "form.stockUpHour",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticStyle: { width: "80px" } },
                                          [_vm._v("小时后")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.form.fetchTypeItems &&
                            _vm.form.fetchTypeItems.includes(3) &&
                            _vm.$route.query.type != "dianCan"
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "goodsExpressFeeType",
                                      label: "快递运费",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "x-f",
                                        staticStyle: { "margin-bottom": "6px" },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 1 },
                                            model: {
                                              value:
                                                _vm.form.goodsExpressFeeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "goodsExpressFeeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.goodsExpressFeeType",
                                            },
                                          },
                                          [_vm._v(" 统一运费： ")]
                                        ),
                                        _c("el-input-number", {
                                          staticStyle: { "text-align": "left" },
                                          attrs: {
                                            controls: false,
                                            precision: 2,
                                            step: 0.01,
                                            min: 0,
                                            disabled:
                                              _vm.form.goodsExpressFeeType !==
                                              1,
                                            label: "请输入统一运费",
                                          },
                                          model: {
                                            value: _vm.form.expressFee,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "expressFee",
                                                $$v
                                              )
                                            },
                                            expression: "form.expressFee",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 2 },
                                            model: {
                                              value:
                                                _vm.form.goodsExpressFeeType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "goodsExpressFeeType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.goodsExpressFeeType",
                                            },
                                          },
                                          [_vm._v(" 运费模板： ")]
                                        ),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              clearable: "",
                                              disabled:
                                                _vm.form.goodsExpressFeeType !==
                                                2,
                                              placeholder: "请选择运费模板",
                                            },
                                            model: {
                                              value: _vm.form.templateId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "templateId",
                                                  $$v
                                                )
                                              },
                                              expression: "form.templateId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.freightTemplate,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.brandId,
                                                attrs: {
                                                  label: item.templateName,
                                                  value: item.templateId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$route.query.type != "dianCan"
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "限购" } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.form.isLimitedQty,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "isLimitedQty",
                                              $$v
                                            )
                                          },
                                          expression: "form.isLimitedQty",
                                        },
                                      },
                                      [_vm._v("限制每人可购买数量")]
                                    ),
                                    _vm.form.isLimitedQty
                                      ? _c("div", { staticClass: "marT10" }, [
                                          _c(
                                            "div",
                                            { staticClass: "x-f" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    onkeyup:
                                                      "this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                                  },
                                                  on: {
                                                    change: function (e) {
                                                      return _vm.changeLimitedQty(
                                                        e,
                                                        "isCycleLimited"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .isLifelongLimited,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "isLifelongLimited",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.isLifelongLimited",
                                                  },
                                                },
                                                [_vm._v("终身限购")]
                                              ),
                                              _c("el-input", {
                                                staticClass: "marL10",
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  disabled:
                                                    !_vm.form.isLifelongLimited,
                                                  type: "number",
                                                  onkeyup:
                                                    "this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.lifelongLimitedQty,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "lifelongLimitedQty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.lifelongLimitedQty",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "marL10" },
                                                [_vm._v("件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "x-f marT10" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  on: {
                                                    change: function (e) {
                                                      return _vm.changeLimitedQty(
                                                        e,
                                                        "isLifelongLimited"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.isCycleLimited,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "isCycleLimited",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.isCycleLimited",
                                                  },
                                                },
                                                [_vm._v("按周期限购")]
                                              ),
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "marL10",
                                                  staticStyle: {
                                                    width: "120px",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !_vm.form.isCycleLimited,
                                                    clearable: "",
                                                    placeholder: "请选择周期",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.cycleLimitedType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "cycleLimitedType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.cycleLimitedType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.dict.type.cycle_limited,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.brandId,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                              _c("el-input", {
                                                staticClass: "marL10",
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  disabled:
                                                    !_vm.form.isCycleLimited,
                                                  type: "number",
                                                  onkeyup:
                                                    "this.value= this.value.match(/\\d+(\\.\\d{0,2})?/) ? this.value.match(/\\d+(\\.\\d{0,2})?/)[0] : ''",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.cycleLimitedQty,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "cycleLimitedQty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.cycleLimitedQty",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "marL10" },
                                                [_vm._v("件")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品详情" } },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("el-upload", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      staticClass: "avatar-uploader",
                                      attrs: {
                                        action: _vm.uploadUrl,
                                        "show-file-list": false,
                                        "on-success": _vm.uploadSuccess,
                                      },
                                    }),
                                    _c("quill-editor", {
                                      ref: "QuillEditor",
                                      staticClass: "marB20",
                                      attrs: { options: _vm.editorOption },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onEditorChange($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.form.goodsDetail,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "goodsDetail", $$v)
                                        },
                                        expression: "form.goodsDetail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      false
        ? _c(
            "div",
            {
              staticClass: "marL10 previewBox padd15",
              staticStyle: { width: "calc(30% - 10px)" },
            },
            [_vm._v(" ssss ")]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          staticClass: "goodsUnit",
          attrs: {
            title: "商品单位设置",
            visible: _vm.OpenUnit,
            width: "30%",
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.OpenUnit = $event
            },
          },
        },
        [
          _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "unitTotal" }, [
              _vm._v(" 共有"),
              _c("span", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.unitList.length)),
              ]),
              _vm._v("种单位 "),
            ]),
            _c("div", { staticClass: "addUnit" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.addUnit,
                    expression: "addUnit",
                  },
                ],
                staticClass: "ADDinput",
                attrs: { type: "text", placeholder: "请输入新单位" },
                domProps: { value: _vm.addUnit },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.addUnit = $event.target.value
                  },
                },
              }),
              _c(
                "span",
                {
                  staticClass: "ADDbtn",
                  on: {
                    click: function ($event) {
                      return _vm.setUnitList(_vm.addUnit)
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "unitTable" }, [
            _c(
              "div",
              { staticClass: "table" },
              _vm._l(_vm.unitList, function (item, index) {
                return _c("div", { key: item.dictCode, staticClass: "item" }, [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("el-input", {
                        ref: "unitFocus",
                        refInFor: true,
                        staticClass: "unit",
                        attrs: {
                          type: "text",
                          readonly: !(_vm.readonly === index),
                        },
                        model: {
                          value: item.dictValue,
                          callback: function ($$v) {
                            _vm.$set(item, "dictValue", $$v)
                          },
                          expression: "item.dictValue",
                        },
                      }),
                      _c("div", { staticClass: "set" }, [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showSave === index,
                              expression: "showSave === index",
                            },
                          ],
                          staticClass: "el-icon-circle-check",
                          on: {
                            click: function ($event) {
                              return _vm.updateUnit(item.dictCode, index)
                            },
                          },
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !(_vm.showSave === index),
                              expression: "!(showSave === index)",
                            },
                          ],
                          staticClass: "el-icon-edit",
                          on: {
                            click: function ($event) {
                              return _vm.unitGetFocus(index)
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
      _c("selectMachining", {
        attrs: {
          OpenMachining: _vm.OpenMachining,
          machiningData: _vm.machiningData,
        },
        on: {
          "update:OpenMachining": function ($event) {
            _vm.OpenMachining = $event
          },
          "update:open-machining": function ($event) {
            _vm.OpenMachining = $event
          },
          getMachining: _vm.pitchMachining,
        },
      }),
      _c("ShopSpecDialog", {
        attrs: {
          show: _vm.shopStrategy,
          shopItem: _vm.shopItem,
          shopMatchingItems: _vm.shopMatchingItems,
        },
        on: { closeDia: _vm.closeDia, selectShopOk: _vm.selectShopOk },
      }),
      _c("SelectGoods", {
        attrs: { isShopp: true, OpenGoods: _vm.OpenGoods },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.OpenGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.OpenGoods = $event
          },
          getGoodsIds: _vm.pitchGoods,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }