<template>
  <div class="wrap" v-loading="loading">
    <!-- <draggable
      v-model="treeData"
      group="items"
      @start="drag=true"
      @end="drag=false"
    >
      <div
        v-for="(item, index) in treeData"
        :key="index"
      >
        <div>{{ item.name }}</div>
        <draggable v-model="item.children" group="items" @start="drag=true" @end="drag=false">
          <div v-for="(child, idx) in item.children" :key="idx">
            {{ child.name }}
          </div>
        </draggable>
      </div>
    </draggable> -->
    <TablePage
      ref="tablePage"
      v-model="options"
      @handleEvent="handleEvent"
      :key="tableKey"
    />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import draggable from "vuedraggable";
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import {
  synCategorySortNo,
  listSimpleCategoryTree,
  categoryList,
  categoryRemove,
  categoryUpdateSortNo,
  categoryReduction,
} from "@/api/O2OMall/goods/category";
import { deepCopy } from "@/utils";

export default {
  name: "QueryManSet",
  components: { TablePage, Dialog, draggable },
  data() {
    return {
      loading: false,
      drag: false,
      treeData: [
        {
          name: "Item 1",
          children: [{ name: "Subitem 2.1" }, { name: "Subitem 2.2" }],
        },
        {
          name: "Item 2",
          children: [{ name: "Subitem 2.1" }, { name: "Subitem 2.2" }],
        },
      ],
      dialogOptions: {
        title: "",
        width: 620,
        show: false,
        type: "O2OGoodsCategory",
        formData: null,
      },
      options: {
        uuid: "3f67fbdb-95db-fd8b-3ee2-beb1267b656d",
        classTitle: true, // 多选
        mutiSelect: true, // 多选
        loading: true,
        getListApi: categoryList,
        getDataKey: (res) => {
          this.listOld = deepCopy(res.data);
          let list = deepCopy(this.handleTree(res.data, "categoryId"));
          console.log("list1", deepCopy(list));
          list = this.sortTreeBySortNo(list);
          console.log("list2", deepCopy(list));
          return {
            list,
          };
        },
        body: {},
        title: "商城",
        check: [], // 选中数据
        rowKey: "categoryId",
        tableTitle: true,
        hidePagination: true,
        treeProps: {
          children: "children",
          hasChildren: "hasChildren",
        },
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            icon: "el-icon-delete",
            alertText: "确认要删除选中商品分类吗？",
            type: "danger",
            btnType: "dropdown",
            trigger: "click", // 要求
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: this.getCategoryReduction,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "categoryName",
            label: "商城分类名称",
            align: "left",
            minWidth: 160,
          },
          {
            prop: "categoryNo",
            label: "商城分类编号",
            type: "link",
            click: "update",
            minWidth: 160,
          },
          {
            prop: "imageUrl",
            label: "商城分类图片",
            type: "image",
            minWidth: 100,
          },
          {
            prop: "sort",
            label: "排序",
            type: "button",
            button: [
              { text: "上移", click: "up" },
              { text: "下移", click: "down" },
            ],
            minWidth: 80,
          },
          {
            prop: "remark",
            align: "center",
            label: "备注",
            minWidth: 360,
          },
        ],
        list: [],
      },
      listOld: [],
      tableKey: Date.now(),
    };
  },
  methods: {
    async getCategoryReduction(arr) {
      await categoryReduction(arr);
      this.tableKey = Date.now();
    },
    sortTreeBySortNo(tree) {
      tree.sort((a, b) => a.sortNo - b.sortNo); // 先对顶级节点按sortNo排序
      tree.forEach((node) => {
        if (node.children && node.children.length > 0) {
          this.sortTreeBySortNo(node.children); // 递归排序子节点
        }
      });
      return tree;
    },
    updateIndex(routeprocessList) {
      routeprocessList.forEach((item, index) => {
        item.orderNum = index + 1;
      });
    },
    async handleEvent(type, row) {
      switch (type) {
        case "handleChoose":
          this.copyList = this.options.list;
          break;
        case "handleMove":
          break;
        case "dragTableEnd":
          console.log("dragTableEnd", row);
          try {
            this.loading = true;
            await synCategorySortNo(row);
            this.tableKey = Date.now();
          } finally {
            const awaitTime = (t = 100) =>
              new Promise((resolve) => setTimeout(resolve, t));
            await awaitTime(1000);
            await this.$nextTick();
            this.loading = false;
          }
          break;
        case "dragTableEndErr":
          this.$nextTick(() => {
            this.$refs.tablePage.getList();
          });
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "up":
          try {
            await categoryUpdateSortNo({
              categoryId: row.categoryId,
              sortType: 1,
            });
            this.$modal.msgSuccess("上移成功");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "down":
          try {
            await categoryUpdateSortNo({
              categoryId: row.categoryId,
              sortType: -1,
            });
            this.$modal.msgSuccess("下移成功");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "update":
          {
            const options = {
              show: true,
              title: "编辑商品分类",
              click: type,
              formData: row,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增商品分类",
              click: type,
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del": // 选中数据 Array
          const categoryIds = this.options.check.map((x) => x.categoryId);
          categoryRemove(categoryIds).then((res) => {
            this.$modal.msgSuccess("删除成功");
            this.tableKey = Date.now();
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  .dialog-footer {
    height: 80px;
  }
}
</style>
